import React from 'react'
import PropTypes from 'prop-types'
import marked from 'marked'

export const HTMLContent = ({ content, className }) => {
  if (!content) {
    return null;
  }
  const markup = marked(content, {sanitize: false});
  return <div className={className} dangerouslySetInnerHTML={{ __html: markup }} />
};

const Content = ({ content, className }) => (
  <div className={className}>{content}</div>
)

Content.propTypes = {
  content: PropTypes.node,
  className: PropTypes.string,
}

HTMLContent.propTypes = Content.propTypes

export default Content
